import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout';
import { Footer } from '../sections/footer';
import { GlobalHeader } from '../sections/global-header';
import { CookieBanner } from '../components/cookie-banner';
import { TextHeader } from '../sections/text-header';
import { ModularContent } from '../sections/modular-content';
import { Metadata } from '../components/metadata';

export default class Page extends React.Component {
  render = () => {
    const {
      title,
      category,
      image,
      content,
      metadata
    } = this.props.data.dato.blogArticle;

    return (
      <Layout >
        <Metadata {...metadata} />
        <GlobalHeader {...this.props.pageContext.header} white />
        <main>
          <TextHeader
            contentPage
            backLinkTitle="All blog articles"
            backLink={{ slug: '/insights' }}
            title={title}
            description={category.title}
            image={image}
          />
          <ModularContent content={content} />
        </main>
        <Footer {...this.props.pageContext.footer} />
        <CookieBanner {...this.props.pageContext.cookie} />
      </Layout>
    )
  }
}

export const query = graphql`
  query BlogDetailQuery($slug: String!) {
    dato {
      blogArticle(filter: { slug: { eq: $slug } }) {
        metadata {
          title
          description
          image {
            url
          }
        }
        title
        author
        category {
          title
          slug
        }
        image {
          url
        }
        content {
          __typename
          ... on DatoCMS_ContentTextRecord {
            text
          }
          ... on DatoCMS_ContentVideoRecord {
            url
          }
          ... on DatoCMS_ContentImageRecord {
            image {
              url
            }
          }
        }
      }
    }
  }
`;